import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Link } from "gatsby"


class IndexPage extends Component {

  render() {
    return (
      <Layout>
        <SEO title="Home" />

        <div className="home-banner-bg">
          <div className="home-banner">
            <div className="container">
              <h1 className="home-banner-content">
                <strong>GradeA Group</strong> is a young leader in the property and construction sector in the AsiaPacific Region. Our aim is to improve project value by offering modern and innovative solutions through high quality building supplies and front-end consultancy in property development.
              </h1>
            </div>
          </div>

          <div class="banner-logo">
            <img src={`images/logo-01.png`} alt="Logo" />
          </div>
        </div>

        <div className="banner-skew">
          <div className="skew-left"></div>
        </div>

        <div className="container">
          <div className="home-feature">
            <h2>Building Supplies</h2>
            <div>
              <p>GradeA Building Supplies is the distribution partner of Brucha Panels in New Zealand, Australia and the Pacific Islands.</p>
            </div>
          </div>

          <div className="home-feature">
            <h2>Development Consultancy</h2>
            <div>
              <p>Aim Projects is the property development consultancy arm of GradeA Group. Our goal is to turn an idea into a vision, then into a development strategy, and then set-up the implementation plan.</p>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default IndexPage
